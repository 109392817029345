<template>
  <form class="depositPage" :class="{ 'form-group--error': $v.$error }" @submit.prevent="onSubmit">
    <div id="section-content" class="container container-fluid">
      <div class="bar-back">
        <router-link to="/member/main"><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link>
      </div>
      <div class="p-2 w-100 bg-light main-content align-self-stretch" style="min-height: calc((100vh - 140px) - 50px)">
        <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0">
          <div class="lotto-title">
            <h4><i class="fas fa-donate"></i> แจ้งเติมเครดิต</h4>
          </div>
        </div>
        <div class="bgwhitealpha text-secondary shadow-sm rounded p-1 mb-5 xtarget col-lotto" v-if="getPermisstionDeposit == false">
          <div class="row w-100 p-0 m-0 d-flex justify-content-center">
            <div id="destep1" class="row col-12 col-sm-12 col-md-6 py-2 px-0 my-1 border rounded">
              <div class="col-12 px-1">
                <h1>ปิดปรับปรุงชั่วคราว</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="bgwhitealpha text-secondary shadow-sm rounded p-1 mb-5 xtarget col-lotto" v-if="getPermisstionDeposit == true">
          <div class="row w-100 p-0 m-0 d-flex justify-content-center">
            <div id="destep1" class="row col-12 col-sm-12 col-md-6 py-2 px-0 my-1 border rounded">
              <div class="col-12 px-1">
                <h6 class="font-weight-normal">
                  <span class="badge badge-pill badge-success font-weight-normal">STEP 1</span> บัญชีธนาคาร
                </h6>
              </div>

              <div class="col-12" :style="[confirmDeposit ? { display: 'none' } : '']">
                <label id="labelselectbank"><i class="fas fa-university"></i> เลือกบัญชีธนาคารของเว็บ</label>
                <div class="border rounded mb-2">
                  <select class="form-control" v-model="adminBank">
                    <option value="">กรุณาเลือกธนาคาร</option>
                    <option v-for="item in adminListBank" :key="item.bankID" :value="item">
                      <div class="text-uppercase">{{ item.bankName.toUpperCase() }}</div>
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-12 py-1">
                <div class="border rounded bg-light">
                  <div class="row">
                    <div class="col-12">
                      <div class="row py-2 px-3 justify-content-center" :style="[adminBank ? { display: 'none' } : '']">
                        <div class="col-3 col-sm-3 col-md-4 col-lg-3 pt-2">
                          <img
                            src="@/assets/icon/bank.png"
                            alt=""
                            width="100%"
                            id="bank2"
                            class="detail-bank table-secondary rounded p-0"
                          />
                        </div>
                        <div
                          class="
                            col-9 col-sm-9 col-md-8 col-lg-5
                            p-2
                            d-flex
                            flex-column
                            justify-content-center
                            align-items-start
                          "
                        >
                          <h6 class="numacc" id="acc2" style="color: black">กรุณาเลือก บัญชีธนาคาร ของเว็บ</h6>
                          <span class="badge badge-pill badge-secondary font-weight-normal" id="nameacclang"></span>
                        </div>
                        <button
                          class="btn btn-light btn-sm border border-secondary copyacc mr-3"
                          type="button"
                          style="display: none"
                        >
                          <i class="fas fa-copy"></i> COPY
                        </button>
                      </div>
                      <div class="row py-2 px-3 justify-content-center" v-if="adminBank" style="color: green">
                        <div class="col-3 col-sm-3 col-md-4 col-lg-3 pt-2">
                          <img
                            :src="imglink(adminBank.bankName)"
                            width="80%"
                            id="bank2"
                            class="detail-bank rounded"
                            :style="'background: ' + (adminBank ? adminBank.bankColor : '')"
                          />
                        </div>
                        <div
                          class="
                            col-9 col-sm-9 col-md-8 col-lg-5
                            p-2
                            d-flex
                            flex-column
                            justify-content-center
                            align-items-start
                          "
                          v-if="confirmDeposit"
                        >
                          <h6 class="numacc" id="acc2" style="margin: 0 0 0.25rem 0">
                            {{ adminBank.bankNo }}
                          </h6>
                          <span class="badge badge-pill badge-success font-weight-normal" id="nameacclang"
                            >ชื่อบัญชี</span
                          >

                          <span id="name2" style="font-size: 1rem">{{ adminBank.bankOwner }}</span>
                        </div>
                        <button
                          class="btn btn-light btn-sm border border-secondary copyacc mr-3"
                          type="button"
                          style="justify-content: center; align-items: center"
                          v-clipboard:copy="adminBank.bankNo"
                          v-on:click="copyAlert"
                          v-if="confirmDeposit"
                        >
                          <i class="fas fa-copy mr-1"></i>COPY
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-100"></div>
            <div id="destep2" class="row col-12 col-sm-12 col-md-6 py-2 px-0 my-1 border rounded" v-if="adminBank">
              <div class="col-12 px-1">
                <h6 class="font-weight-normal">
                  <span class="badge badge-pill badge-success font-weight-normal">STEP 2</span> ใส่จำนวนเงินที่ต้องการ
                </h6>
              </div>
              <div :style="[confirmDeposit ? { display: 'none' } : '']">
                <div class="col-12">
                  <label><i class="fas fa-money-bill"></i> จำนวนเงิน</label>
                  <input
                    autocomplete="off"
                    type="tel"
                    class="form-control moneyinput"
                    id="CurrencyInput"
                    name="money"
                    maxlength="10"
                    v-model.trim="$v.amount.$model"
                  />
                </div>
                <div class="col-12 pt-1" v-if="promotion_enable">
                  <label><i class="fas fa-gifts"></i> โปรโมชั่น</label>
                  <div class="col-12 text-center pb-2" v-if="promotoion_id > 0">
                    <img @click="$bvModal.show('modal-promotions')" :src="this.current_promotion.picture" class="-cover-img img-fluid ls-is-cached lazyloaded" alt="wt-pretty-gaming" width="40%"/>
                  </div>
                    <select @change="change_promotion" class="form-control x-form-control" name="show_promotion" v-model="promotoion_id" >
                      <option value="0">-</option>
                      <option :value="item.id" v-for="(item,index) in getAgentPromotionsDeposit"  v-bind:key="index">{{ item.name }}</option>
                    </select>
                </div>
                <div class="col-12 mt-3">
                  <div class="alert alert-warning p-2 mb-2">คำเตือน! กรุณาใช้บัญชีที่ท่านผูกกับเว็ป ในการโอนเงินเท่านั้น</div>
                  <div class="alert alert-info p-2">
                    <p class="m-0 p-0">เมื่อสมาชิกเติมเงินแล้วจะต้องมียอดแทง <small> 50% </small>จึงจะสามารถถอนเงินได้ </p>
                    <!-- <p class="m-0 p-0">เมื่อท่านทำการโอนเงินไปยังบัญชีข้างต้นเรียบร้อยแล้ว <small>(เก็บสลิปการโอนไว้ทุกครั้ง)</small>
                    <br /><b>"คลิกปุ่มด้านล่าง"</b> <u>เพื่อแจ้งกำเนินการต่อ</u> -->
                  </div>
                  <button type="button" class="btn btn-primary btn-block btn-larg" @click="submitDeposit">
                    ใส่จำนวนเงินแล้ว คลิกที่นี่
                  </button>
                </div>
              </div>
            </div>

            <div class="w-100"></div>
            <div id="destep3" class="row col-12 col-sm-12 col-md-6 py-2 px-0 my-1 border rounded" v-if="confirmDeposit">
              <div class="col-12 px-1">
                <h6 class="font-weight-normal">
                  <span class="badge badge-pill badge-success font-weight-normal">STEP 3</span> แจ้งรายละเอียดการโอนเงิน
                </h6>
              </div>
              <div class="col-12 text-center">
                <h5><span class="text-danger timetransferlimit"></span></h5>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-12">
                    <label><i class="fas fa-money-bill"></i> จำนวนเงินที่ต้องโอน</label>
                    <input
                      type="tel"
                      class="form-control text-center text-danger moneyinput f4vh"
                      id="CurrencyInput"
                      name="money"
                      maxlength="10"
                      v-model.trim="$v.amount.$model"
                      disabled
                    />
                  </div>
                  <div class="col-12 mt-1">
                    <label><i class="fas fa-calendar-alt"></i> วันที่โอน </label>
                    <v-date-picker
                      :max-date="new Date()"
                      :masks="masks"
                      :model-config="modelConfig"
                      v-model.trim="$v.date.$model"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <div class="input-group date">
                          <input
                            :value="inputValue"
                            v-on="inputEvents"
                            class="form-control datetimepicker-input mb-0"
                            style="background-color: #ffffff"
                            readonly
                            disabled
                          />
                          <div class="input-group-append">
                            <div class="btn btn-outline-secondary">
                              <i class="fas fa-calendar my-0"></i>
                            </div>
                          </div>
                        </div>
                      </template>
                    </v-date-picker>
                  </div>
                  <div class="col-12 mt-1">
                    <!-- <label><i class="far fa-clock"></i> เวลาที่โอน </label>
                    <div class="input-group">
                      <select name="time_hour" v-model="time_trasfer" class="form-control select-time" data-fv-field="check_hours">
                        <option v-for="(item, index) in time_ban" v-bind:key="index">
                        {{ item }}
                        </option>
                      </select>
                      <div class="input-group-prepend">
                        <span class="input-group-text" style="background-color: #ffffff;"><span class="fas fa-clock"></span></span>
                      </div>
                    </div> -->
                    <!--<div class="input-group date">
                      <vue-timepicker
                        v-model.trim="$v.time_trasfer.$model"
                        placeholder=" "
                        hide-clear-button
                        close-on-complete
                        inputClass="form-control form-fix"
                      />
                      <div class="input-group-append special-append">
                        <div class="btn btn-outline-secondary"><i class="fas fa-clock"></i></div>
                      </div>
                    </div>-->
                  </div>
                  <div class="col-12">
                    <small class="text-danger mt-2" style="display: inline-block; line-height: 1.2">
                      * กรุณากรอก วัน-เวลา ในการโอนให้ตรงกับ Slip ระบบจะเติมเครดิตให้คุณอัตโนมัติ
                    </small>
                    <br />
                    <!--<label class="mt-2"><i class="far fa-star"></i> หมายเหตุ</label>
                    <textarea
                      name="note"
                      id="note"
                      cols="30"
                      rows="2"
                      class="form-control"
                      style="resize: none"
                      v-model="note"
                    ></textarea>-->
                  </div>
                  <div class="col-12 mb-2">
                    <small
                      >กรุณาตรวจสอบข้อมูลของท่านให้ถูกต้อง</small
                    >
                  </div>
                </div>
                <!--<button class="btn btn-success btn-block btn-larg" type="submit">ยืนยันการแจ้งโอนเงิน</button>-->
                <a class="btn btn-danger btn-block btn-larg" @click="calcleBankStagement">ยกเลิก</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal style="height: 100%;" id="modal-promotions" scrollable hide-footer size="xl" v-if="promotoion_id > 0">
      <img  :src="this.current_promotion.picture"  class="w-100 bg-radius">
                        <h1 class="m-0 p-2 text-white">{{ this.current_promotion.name }}</h1>
                        <p class="m-0 px-2  text-white">{{ this.current_promotion.note }}</p>
      <b-button class="mt-3" variant="primary" size="sm" block @click="$bvModal.hide('modal-promotions')">ปิด</b-button>
    </b-modal>
  </form>
</template>

<script>
// import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import { required } from 'vuelidate/lib/validators'
import { mapState, mapActions, mapGetters } from 'vuex'
import { checkNumeric, isEmpty, get } from '@/utils/'
import { submitDepositService, confirmDepositService, depositCachViewService, getStatusDepService, CancleDepositTransferService } from '@/services'
import { STATUS_SUCCESS } from '@/constants'
import kbank from '@/assets/banks/kbank.svg'
import ktb from '@/assets/banks/ktb.svg'
import scb from '@/assets/banks/scb.svg'
import moment from 'moment-timezone'
export default {
  components: {
    // VueTimepicker
  },
  data () {
    return {
      date: this.$moment().format('DD/MM/YYYY'),
      adminBank: '',
      userBank: '',
      adminListBank: {},
      confirmDeposit: false,
      note: '',
      amount: '',
      time_trasfer: this.$moment().format('HH:mm'),
      time_ban: [],
      masks: {
        input: 'DD/MM/YYYY'
      },
      modelConfig: {
        type: 'string',
        input: 'DD/MM/YYYY',
        mask: 'DD/MM/YYYY'
      },
      imageBankMapping: {
        kbank: kbank,
        ktb: ktb,
        scb: scb
      },
      timeInterval: 3000,
      promotion_enable: process.env.VUE_APP_PROMOTION_ENABLE,
      promotoion_id: 0,
      current_promotion: {}
    }
  },
  async mounted () {
    await this.setLoading(true)
    await this.reqConfig(process.env.VUE_APP_AG_ID)
    if (Number(this.getDepositMode) === Number(1)) {
      this.$router.replace('/member/depositP')
    }
    this.adminListBank = await this.inquiryListBank()
    await this.getMyAccountBanks()
    const resp = await depositCachViewService()
    if (resp.status_code === STATUS_SUCCESS) {
      if (isEmpty(resp.data) === false) {
        this.amount = resp.data.amount
        this.confirmDeposit = true
        this.adminBank = resp.data.bankInfo
        this.timer = setInterval(() => {
          this.getMyStagementDep()
        }, this.timeInterval)
      }
    }
    for (let i = 0; i < 10; i++) { // 10 minute after Asia/Bangkok
      const timePush = moment().tz('Asia/Bangkok').add(i, 'minute').format('HH:mm')
      this.time_ban.push(timePush)
    }
    await this.setLoading(false)
  },
  computed: {
    ...mapState({
      myBankInfo: state => state.bankModule.myBankInfo
    }),
    ...mapGetters([
      'getDepositMode',
      'getAgentPromotionsDeposit',
      'getPermisstionDeposit'
    ])
  },
  watch: {
    amount: function (value) {
      if (!checkNumeric(value)) {
        this.amount = value.substring(0, value.length - 1)
      } else if (value.length === 1 && (value === '0' || value === '.')) {
        this.amount = ''
      }
    }
  },
  async created () {
  },
  beforeDestroy () {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    ...mapActions(['reqConfig', 'inquiryListBank', 'getMyAccountBanks', 'setLoading', 'openInfoPopup', 'openErrorPopup', 'setAdminBank']),
    copyCode: async function (e) {
      this.code = e.target.value
      const copyText = await this.$refs.myInput
      copyText.select()
      document.execCommand('copy')
    },
    change_promotion () {
      this.current_promotion = this.getAgentPromotionsDeposit.filter((x) => x.id === this.promotoion_id)[0]
      console.log(this.current_promotion)
    },
    async submitDeposit () {
      await this.setLoading(true)
      try {
        const acountID = this.adminBank.bankID.toString()
        const resp = await submitDepositService(this.amount, acountID, this.promotoion_id)
        if (resp.status_code === STATUS_SUCCESS) {
          this.amount = resp.data.amount
          this.confirmDeposit = true
          this.timer = setInterval(() => {
            this.getMyStagementDep()
          }, this.timeInterval)
        }
      } catch (error) {}
      await this.setLoading(false)
    },
    async onSubmit () {
      this.$v.$touch()
      await this.setLoading(true)
      if (this.$v.$pending || this.$v.$error) {
        window.scrollTo(0, 0)
        this.openInfoPopup('กรุณากรอกข้อมูลให้ครบถ้วน')
      } else {
        try {
          const acountID = this.adminBank.bankID.toString()
          const resp = await confirmDepositService(this.amount, acountID, this.time_trasfer, this.note)
          if (resp.status_code === STATUS_SUCCESS) {
            this.setAdminBank(this.adminBank)
            this.$router.replace('/member/main')
            this.openInfoPopup()
          }
        } catch (error) {
        }
      }
      await this.setLoading(false)
    },
    async getMyStagementDep () {
      const resp = await getStatusDepService()
      if (resp.status_code === STATUS_SUCCESS) {
        if (resp.data === 'done') {
          clearInterval(this.timer)
          this.timer = null
          this.$router.replace('/member/request/all')
          this.openInfoPopup()
        }
      }
    },
    imglink (short) {
      return get(this.imageBankMapping, [short])
    },
    copyAlert () {
      this.openInfoPopup()
    },
    async calcleBankStagement () {
      const resp = await CancleDepositTransferService()
      if (resp.status_code === STATUS_SUCCESS) {
        if (resp.data === 'success') {
          clearInterval(this.timer)
          this.timer = null
          this.$router.replace('/member/request/all')
          this.openInfoPopup()
        } else {
          alert(resp.data)
        }
      }
    }
  },
  validations () {
    return {
      amount: {
        required
      },
      date: {
        required
      },
      time_trasfer: {
        required
      }
    }
  }
}
</script>

<style scoped>
button.close {
  color: white !important;
}
.copytotal,
.copyacc {
  display: flex;
  position: absolute !important;
  bottom: 2px;
  right: 2px;
  padding: 3px 5px;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
}
.btn-outline-secondary:hover {
  cursor: default;
  color: #6c757d;
  background-color: white;
}
.btn-outline-secondary:active {
  color: #6c757d !important;
  background-color: white !important;
}
.special-append {
  position: absolute;
  right: 0;
}
.f4vh {
  font-size: 4vh;
}
</style>
